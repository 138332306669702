body {
  margin: 0;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
#dooey-logo {
  font-family: 'Poppins','Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.font-fontawesome {
  font-family: 'FontAwesome';
  font-style: normal
}

#root {
  height:100%;
}

html {
  height:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea:focus, input:focus{
  outline: none;
}

.Toastify__toast-body {
  font-size:13px;
  color:#444;
}

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,400i,500,600,700,900');

@tailwind base;
@tailwind components;
@tailwind utilities;